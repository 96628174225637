export function initHeatmap() {
  const input = document.getElementById("input").innerHTML;

  //const bytesText=Array.from(''+input+'').map((each)=>each.charCodeAt(0).toString(2)).join(" ");

  //console.log(bytesText);

  function stringToUTF8Bytes(string) {
    return new TextEncoder().encode(string);
  }

  const bytesB = stringToUTF8Bytes(input);

  // function buf2hex(buffer) {
  //     let u = new Uint8Array(buffer),
  //         a = new Array(u.length),
  //         i = u.length;
  //     while (i--) // map to hex
  //         a[i] = (u[i] < 16 ? '0' : '') + u[i].toString(16);
  //     u = null; // free memory
  //     return a.join('');
  // }

  //console.log(buf2hex(bytesB));
  // const hex=buf2hex(bytesB);
  //const hexArray = hex.match(/\S+/g);

  //console.log(hexArray);

  function buf2dec(buffer) {
    let u = new Uint8Array(buffer),
      a = new Array(u.length),
      i = u.length;
    while (i--)
      // map to dec
      a[i] = (u[i] < 10 ? "0" : "") + u[i].toString(10);
    u = null; // free memory
    return a.join(" ");
  }

  //console.log(buf2dec(bytesB));
  const dec = buf2dec(bytesB);
  const decArray = dec.match(/\S+/g).map(Number);

  //console.log(decArray);

  function buf2bytes(buffer) {
    let u = new Uint8Array(buffer),
      a = new Array(u.length),
      i = u.length;
    while (i--)
      // map to dec
      a[i] = (u[i] < 2 ? "0" : "") + u[i].toString(2);
    u = null; // free memory
    return a.join(" ");
  }

  // console.log(buf2bytes(bytesB));
  const bbytes = buf2bytes(bytesB);
  //const bytesArray = bbytes.match(/\S+/g).map(Number);
  // console.log(bytesArray);

  const textToBinary = (str = "") => {
    let res = "";
    res = str
      .split("")
      .map((char) => {
        return char.charCodeAt(0).toString(2);
      })
      .join("");
    return res;
  };

  // console.log(textToBinary(input));

  // function utf8ToHex(str) {
  //   return Array.from(str).map(c =>
  //     c.charCodeAt(0) < 128 ? c.charCodeAt(0).toString(16) :
  //     encodeURIComponent(c).replace(/\%/g,'').toLowerCase()
  //   ).join('');
  // }

  // console.log(utf8ToHex(input));

  function splitStringBySegmentLength(source, segmentLength) {
    if (!segmentLength || segmentLength < 1)
      throw Error("Segment length must be defined and greater than/equal to 1");
    const target = [];
    for (
      const array = Array.from(source);
      array.length;
      target.push(array.splice(0, segmentLength).join(""))
    );
    return target;
  }

  const sourcebyte = textToBinary(input);
  //const sourcehex = utf8ToHex(input);
  //const target2 = source.split(' '); add join in source

  const bytePairs = splitStringBySegmentLength(sourcebyte, 8);

  //const hexPairs=splitStringBySegmentLength(sourcehex,6);

  // console.log(hexPairs);

  // console.log(bytePairs);

  function changeBase(number, fromBase, toBase) {
    if (fromBase == 10) return parseInt(number).toString(toBase);
    else if (toBase == 10) return parseInt(number, fromBase);
    else {
      let numberInDecimal = parseInt(number, fromBase);
      return parseInt(numberInDecimal).toString(toBase);
    }
  }

  const newNumbers = bytePairs.map(function (xx) {
    xx = changeBase(xx, 2, 10);
    return xx;
  });

  //console.log(newNumbers);

  const noise = textToBinary(input);

  const quantumExperiment = {
    pointer: 0,
    rng: function (bits) {
      if (this.pointer + bits >= noise.length) {
        this.pointer = 0;
      }
      var result = parseInt(noise.slice(this.pointer, this.pointer + bits), 2);
      this.pointer += bits;
      return result / 2 ** bits;
    },
  };

  //var rng  = (bits => quantumExperiment.rng(bits));

  // function getRandom(max, min) {
  //   return Math.round((Math.random() * (max - min + 1) + min) * 10) / 10;
  // }

  //export function initHeatmap() {

  const newArrA = decArray;
  const newArrB = newNumbers;
  var newArrX = newArrA.slice(0);
  var newArrY = newArrB.slice(0);
  // console.log(newArrY);
  // console.log(newArrY.length);

  var rng = (bits) => quantumExperiment.rng(bits);

  let heatmap = null;

  //const canvasheatmap=document.getElementById("heatmap");
  heatmap = h337.create({
    container: document.getElementById("heatmap"),
    // width: 272,
    // height: 144,

    width: 1048,
    height: 544,
    
    radius: 20,
    blur: 0.7,
    //blur: 0.75,
    // opacity:0.9,
    //  maxOpacity: 0.6,
    //  minOpacity: 0.0,
    // backgroundColor: "#000066",

    gradient: {
      // enter n keys between 0 and 1 here
      // for gradient color customization
      // '.5': 'blue',
      // '.8': 'green',
      // '.95': 'red',

      // 0.25: "rgb(0,0,255)",
      // 0.55: "rgb(0,255,0)",
      // 0.85: "yellow",
      // 1.0: "rgb(255,0,0)",

      0.05: "#000066",
      0.25: "#0000ff",
      0.45: "#008800",
      0.55: "#00ff00",
      0.85: "#ffff00",
      0.93: "#ff5500",
      0.98: "#ff0000",
      1.0: "#660000",
    },
  });

  var i = 0;

  const max = 10;
  const dataArr = [];

  // while (i < 2) {
  //   data.push({
  //     // console.log(data);
  //     x: getRandom(1, 512),
  //     y: getRandom(1, 256),
  //     value: getRandom(1, 6)
  //   });
  //   i++;
  // }

  heatmap.setData({
    max: max,
    data: dataArr,
  });

  // timeout();
  var j = 0;
  // function timeout() {
  let timer = 50;
  
  setInterval(function () {
    //console.log(timer);
    var i = 0;
    // console.log("errr");
    //max = 10;
    //data = [];

    if (newArrX.length < 1) {
      newArrX = newArrA.slice(0);
    }

    if (newArrY.length < 1) {
      newArrY = newArrB.slice(0);
    }

    var arrLength = dataArr.length;
    var maxNumber = 2000;
    if (arrLength > maxNumber) {
      dataArr.splice(0, arrLength - maxNumber);
    }

    while (i < 1) {
      var ran1 = rng(16);
      var ranx = rng(32);
      //console.log(ranx);
      var XX = j + parseInt(ranx * newArrX[i]);

      if (XX < 1024) {
        timer = 100;
        // timer = 20;
        
      } else if (XX >= 1024) {
        // XX = Math.floor(Math.random() *(XX/10));
        XX = XX - 1024;
        timer = 1000;
        // console.log(XX);
      } 
      // else if (XX < 0) {
      //   XX = Math.floor(Math.random() *512);
      //   // console.log(XX);
      //   // XX = -XX;
      // }

      var XXX = 12 + XX;

      // if(XX>=128)
      // {XX=256-XX;}
      // var XXX=parseInt((getRandom(0, 6)+ranx)*XX);
      // if(XXX>=512)
      // {XXX=parseInt((getRandom(0, 3)+ranx)*XX);}
      if (j >= 1024) {
        j = 0;
      }

      // console.log(XX);
      dataArr.push({
        y: 8 + (2* (newArrY[i])),
        // y: parseInt(8+XX),
        x: XXX,
        //x: 8+j,
        //x: parseInt(8+((Math.floor(Math.random() * 4)+ranx)*XX)),
        //x: getRandom(4, 512),
        // y: 8+newArrY[i],
        //y: getRandom(8, 248),
        //value: getRandom(1, (8*ran1)),
        value: 4 * ran1,
      });
      newArrX.shift();
      newArrY.shift();
      j++;
      i++;
    }

    // console.log(dataArr);
    heatmap.setData({
      max: max,
      data: dataArr,
    });
  }, timer);
}
