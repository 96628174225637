import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

import { initHeatmap } from "./heat.js";

import { wordlist } from "./words.js";
import { satlistnames } from "./satnames.js";

("use strict");

var data;

// mainsphere();
rantext();

async function rantext() {
  document.getElementById("input").innerHTML = "";
  document.getElementById("scroll").innerHTML = "";
  document.getElementById("scroll2").innerHTML = "";

  let dd, d, c, b, a, aa;
  await all();

  async function all(k = 1) {
    if (k < 666) {
      let index = Math.floor(Math.random() * 8);
      if (index == 0 || index == 1) {
        a = Math.floor(Math.random() * 10000);
        aa = String.fromCharCode(parseInt(a));
      } else if (index == 2 || index == 3) {
        b = Math.floor(Math.random() * 128884);
        aa = String.fromCharCode(parseInt(b));
      } else if (index == 4 || index == 5) {
        b = Math.floor(Math.random() * 222) + 32;
        aa = String.fromCharCode(parseInt(b));
      } else if (index == 6) {
        c = wordlist[(wordlist.length * Math.random()) | 0];
        aa = " " + c.toString() + " ";
      } else if (index == 7) {
        d = satlistnames[(satlistnames.length * Math.random()) | 0];
        dd = " " + d.toString() + " ";
        aa = dd;
      } else {
        aa = " ";
      }
      
      document.getElementById("input").innerHTML += aa;
      k++;
      await all(k);
    } else {
      data = document.getElementById("input").innerHTML;
      await tick();
    }
  }
}



async function tick() {
  let tickerText = "";
  let tickerText2 = "";
  //console.log(data);
  await allt();
  async function allt(i = 0) {
    if (i < data.length) {
      tickerText += String.fromCodePoint(data[i].codePointAt(0));
      if (data[i].codePointAt(0) == 32) {
      } else {
        tickerText2 += data[i].codePointAt(0);
      }

      if (i != data.length - 1) {
        tickerText += "";
        tickerText2 += " ";
      }
      i++;
      await allt(i);
    } else {
      document.getElementById("scroll").innerHTML = tickerText;
      document.getElementById("scroll2").innerHTML = tickerText2;
      document.getElementById("input").innerHTML = data;
      start();
    }
  }
}

function start() {
  mainsphere();
  const para1 = document.getElementById("scroll");
  const para2 = document.getElementById("scroll2");
  para1.style.marginLeft = "10px";
  para2.style.marginLeft = "10px";

  setTimeout(function () {
    animates(para1);
    animates(para2);
  }, 200);
}

function animates(element) {
  let elementWidth = element.offsetWidth;
  let parentWidth = element.parentElement.offsetWidth;
  let flag = 10;

  setInterval(function () {
    element.style.marginLeft = --flag + "px";

    if (elementWidth == -flag) {
      flag = parentWidth;
    }
  }, 20);
}



function mainsphere() {
  initHeatmap();
  const containerthree = document.getElementById("canvasthree");
  const heatmapdiv = document.getElementById("heatmap");
  const canvastrik = heatmapdiv.getElementsByTagName("canvas")[0];

  const scene = new THREE.Scene();
  // scene.background = new THREE.Color("blue");
  const camera = new THREE.PerspectiveCamera(
    50, //fov
    window.innerWidth / window.innerHeight,
    1,
    1000 //far
  );

  const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  renderer.setClearColor(0x000000, 0);
  scene.background = null;
  renderer.setSize(window.innerWidth, window.innerHeight);
  containerthree.appendChild(renderer.domElement);

  camera.position.set(10, 10, 65);

  {
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.target.set(0, 0.0, 0);
    controls.update();
    controls.enablePan = false;
    controls.enableDamping = true;
    controls.minDistance = 0; //101orig
    controls.maxDistance = 200; //101orig
    controls.rotateSpeed = 1; //1
    controls.zoomSpeed = 0.8;
  }

  {
    const color = 0xffffff;

    const light = new THREE.DirectionalLight(color, 0.8);
    light.position.set(150, 50, 0);
    scene.add(light);
    const light2 = new THREE.DirectionalLight(color, 0.4);
    light2.position.set(-150, 0, 0);
    scene.add(light2);
    const light3 = new THREE.DirectionalLight(color, 0.4);
    light3.position.set(0, -50, -150);
    scene.add(light3);
    const light4 = new THREE.DirectionalLight(color, 0.8);
    light4.position.set(0, 0, 150);
    scene.add(light4);

    const lightA = new THREE.AmbientLight(0x888888, 0.5);
    scene.add(lightA);
  }

  const curve = new THREE.EllipseCurve(
    0,
    0, // ax, aY
    20,
    20, // xRadius, yRadius
    0,
    2 * Math.PI, // aStartAngle, aEndAngle
    false, // aClockwise
    0 // aRotation
  );

  const points = curve.getPoints(100);
  const geometrye = new THREE.BufferGeometry().setFromPoints(points);

  const material = new THREE.LineDashedMaterial({
    color: 0xffffff,
    scale: 1,
    dashSize: 1,
    gapSize: 1,
  });

  // Create the final object to add to the scene
  const ellipse = new THREE.Line(geometrye, material);
  ellipse.computeLineDistances();
  //ellipse.rotation.y = Math.PI / 4;
  scene.add(ellipse);

  const curve2 = new THREE.EllipseCurve(
    0,
    0, // ax, aY
    20,
    20, // xRadius, yRadius
    0,
    2 * Math.PI, // aStartAngle, aEndAngle
    false, // aClockwise
    0 // aRotation
  );

  const points2 = curve2.getPoints(100);
  const geometrye2 = new THREE.BufferGeometry().setFromPoints(points2);

  const material2 = new THREE.LineDashedMaterial({
    color: 0xffffff,
    scale: 1,
    dashSize: 1,
    gapSize: 1,
  });

  // Create the final object to add to the scene
  const ellipse2 = new THREE.Line(geometrye2, material2);
  ellipse2.computeLineDistances();

  ellipse2.rotation.x = -Math.PI / 2;

  scene.add(ellipse2);

  const heatMapTexture = new THREE.CanvasTexture(canvastrik);

  const Sphere_RADIUS = 20;
  const heatMapGeo = new THREE.SphereBufferGeometry(Sphere_RADIUS, 1000, 1000);

  const heatMapMaterial = new THREE.MeshPhongMaterial({
    map: heatMapTexture,
    displacementMap: heatMapTexture,
    displacementScale: 4,
    displacementBias: 0.0,
    side: THREE.DoubleSide,
    transparent: true,
  });

  const heatMapPlane = new THREE.Mesh(heatMapGeo, heatMapMaterial);

  heatMapPlane.position.set(0, 0, 0);
  scene.add(heatMapPlane);

  function animate() {
    requestAnimationFrame(animate);

    heatMapTexture.needsUpdate = true;
    heatMapPlane.rotation.y += 0.0002;
    ellipse.rotation.y += 0.0002;
    renderer.render(scene, camera);
  }
  animate();

  window.onresize = function () {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  };
}
